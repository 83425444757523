import React from 'react'
import { Row, Button, Col, DatePicker } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { DateFilter } from 'features/filter/types'
import styled from 'styled-components'

interface Props {
  filter: DateFilter
  onChange(filter: DateFilter): void
}

const DateDurationContent: React.FunctionComponent<Props> = ({
  filter,
  onChange,
}) => {
  const onClear = () => {
    filter.Mode = ''
    filter.StartDate = undefined
    filter.FinishDate = undefined
    onChange(filter)
  }

  const onUpdate = (mode: string, start: Moment, end: Moment) => {
    const date = filter
    if (date.Mode === mode) {
      date.Mode = ''
      date.StartDate = undefined
      date.FinishDate = undefined
    } else {
      date.Mode = mode
      date.StartDate = start
      date.FinishDate = end
    }

    filter = date
    onChange(filter)
  }

  const on7Days = () => {
    const today = moment().startOf('day')
    const last = moment()
      .endOf('day')
      .subtract(7, 'days')

    onUpdate('7days', last, today)
  }

  const on3Week = () => {
    const today = moment().startOf('day')
    const last = moment()
      .endOf('day')
      .subtract(3, 'weeks')

    onUpdate('3week', last, today)
  }

  const on1Month = () => {
    let today = moment().startOf('day')
    let last = moment()
      .endOf('day')
      .subtract(1, 'months')

    onUpdate('1month', last, today)
  }

  const on3Month = () => {
    let today = moment().startOf('day')
    let last = moment()
      .endOf('day')
      .subtract(3, 'months')

    onUpdate('3month', last, today)
  }

  const onCustom = () => {
    let today = moment().startOf('day')
    let last = moment()
      .endOf('day')
      .subtract(7, 'days')
    onUpdate('custom', last, today)
  }

  const onStartChange = (val: null | Moment) => {
    filter.StartDate = val ? val : moment().startOf('day')
    onChange(filter)
  }

  const onEndChange = (val: null | Moment) => {
    filter.FinishDate = val ? val : moment().startOf('day')
    onChange(filter)
  }

  const date = filter
  let content
  if (date.Mode === 'custom') {
    content = (
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            วันที่
          </div>
        </Col>

        <Col span={24} className="datetime-form">
          <div className="input-datetime">
            <div className="label">วันที่เริ่มต้น</div>
            <DatePicker
              className="date-picker"
              value={date.StartDate}
              onChange={onStartChange}
              format="DD/MM/Y"
              placeholder="DD/MM/YYYY"
              allowClear={false}
            />
          </div>

          <div className="input-datetime" style={{ marginTop: '16px' }}>
            <div className="label">วันที่สิ้นสุด</div>
            <DatePicker
              className="date-picker"
              value={date.FinishDate}
              onChange={onEndChange}
              format="DD/MM/Y"
              placeholder="DD/MM/YYYY"
              allowClear={false}
            />
          </div>
        </Col>
      </Row>
    )
  }

  const clearBtn =
    date.Mode !== '' ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        {translate(TKeys.Common.cancel_all)}
      </span>
    ) : (
      undefined
    )
  const active = 'filter-range-btn-action'
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.duration)}
            {clearBtn}
          </div>
        </Col>

        <Col span={24}>
          <div className="button-group">
            <Button
              className={`filter-range-btn ${
                date.Mode === '7days' ? active : ''
              }`}
              onClick={on7Days}
            >
              7 {translate(TKeys.Common.day)}
            </Button>

            <Button
              className={`filter-range-btn ${
                date.Mode === '3week' ? active : ''
              }`}
              onClick={on3Week}
            >
              3 {translate(TKeys.Common.week)}
            </Button>
            <Button
              className={`filter-range-btn ${
                date.Mode === '1month' ? active : ''
              }`}
              onClick={on1Month}
            >
              1 {translate(TKeys.Common.month)}
            </Button>
            <Button
              className={`filter-range-btn ${
                date.Mode === '3month' ? active : ''
              }`}
              onClick={on3Month}
            >
              3 {translate(TKeys.Common.month)}
            </Button>
            {/* <Button
              className={`filter-range-btn ${
                date.Mode === 'custom' ? active : ''
              }`}
              onClick={onCustom}
            >
              {translate(TKeys.Dashboard.custom)}
            </Button> */}
          </div>
        </Col>

        <Col span={24}>
          <Box>
            <Button
              className={`filter-range-btn ${
                date.Mode === 'custom' ? active : ''
              }`}
              onClick={onCustom}
            >
              {translate(TKeys.Dashboard.custom)}
            </Button>
          </Box>
        </Col>
      </Row>

      {content}
    </div>
  )
}

const css: any = {
  page: {
    width: '400px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
}

const Box = styled.div`
  padding: 0 12px 12px 0;
  margin-top: 10px;
`

export default DateDurationContent
